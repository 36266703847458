@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}
.booking-container {
  max-width: 1140px;
  margin: 0 auto;
}
:root {
  --bg-pri-100: #fd4326;
  --bg-sec-100: #26272b;
  --text-sec-100: #26272b;
  --text-pri-100: #fd4326;
}
h1 {
  font-size: 4rem; /* 48px */
  line-height: 5rem;
  font-family: "Roboto", sans-serif;
}
h2 {
  font-size: 2.6rem; /* 36px */
  line-height: 3.5rem; /* 40px */
  font-family: "Roboto", sans-serif;
}

h3 {
  font-size: 1.5rem; /* 24px */
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif;
}
h4 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.25rem; /* 28px */
  font-family: "Roboto", sans-serif;
}
.text-pri-100 {
  color: var(--text-pri-100);
}
.text-sec-100 {
  color: var(--text-sec-100);
}
/* buttons */

form#QuickReservation .btn-primary {
  background-color: #26272b;
}
div[data-theme-1] {
  background-color: #000;
}

.btn1 {
  display: inline-block;
  background-color: var(--bg-pri-100);
  padding: 0.8rem 2rem;
  border-radius: 6px;
  color: #fff;
}
.btn1:hover {
  background-color: var(--bg-sec-100);
  color: #fff;
}
.btn2 {
  display: inline-block;
  background-color: var(--bg-pri-100);
  padding: 0.8rem 2rem;
  border-radius: 6px;
  color: #fff;
}
.btn2:hover {
  background-color: #fff;
  color: var(--bg-sec-100);
}
.nav--item {
  color: var(--text-sec-100);
}
.nav--item:hover {
  color: var(--text-pri-100);
}

/* box-shadow */
.box--shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
/* hero sliders */

.hero--1 {
  /* height: calc(100vh - 95px); */

  background-image: url(../images/hero2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero--2 {
  height: calc(45vh);
  background-image: url(../images/fleets/fleetsHero.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact--hero {
  height: calc(45vh);
  background-image: url(../images/contact-cover.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.help--hero {
  height: calc(45vh);
  background-image: url(../images/help-cover.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about--hero {
  height: calc(45vh);
  background-image: url(../images/about-hero1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero--3 {
  background-image: url(../images/airport3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero--4 {
  background-image: url(../images/map2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero--5 {
  background-image: url(../images/waterfall2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero--cover {
  background: rgba(0, 0, 0, 0.6);
}
.hero--cover-2 {
  /* background: rgba(255, 255, 255, 0.82); */
  background: rgba(0, 0, 0, 0.75);
}
.hero--cover-3 {
  background: rgba(0, 0, 0, 0.8);
  /* background: rgba(255, 255, 255, 0.7); */
}
.hero--cover-4 {
  background: rgba(0, 0, 0, 0.7);

  /* background: rgba(255, 255, 255, 0.82); */
}

/* promise */
.banner1--cover {
  background-image: url(../images/banners/banner1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner2--cover {
  background-image: url(../images/banners/banner2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner3--cover {
  background-image: url(../images/banners/banner33.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.promise {
  background: linear-gradient(90deg, #000 30%, transparent);
}

/* stay cards */
.ant-modal-body {
  padding: 1rem !important;
}

.about--icon {
  height: 7rem;
  min-width: 7rem;
}
/* media queries */

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 850px;
  }
  .booking-container {
    max-width: 850px;
  }
  h1 {
    font-size: 3rem; /* 48px */
    line-height: 4rem;
  }
  h2 {
    font-size: 2.6rem; /* 36px */
    line-height: 2.5rem; /* 40px */
  }

  h3 {
    font-size: 1.5rem; /* 24px */
    line-height: 1.5rem;
  }
  h4 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.25rem; /* 28px */
  }
}
@media only screen and (max-width: 768px) {
  .container {
    max-width: 720px;
  }
  .booking-container {
    max-width: 720px;
  }
  .about--icon {
    height: 5rem;
    min-width: 5rem;
  }
}
@media only screen and (max-width: 640px) {
  .container {
    max-width: 576px;
  }
  .booking-container {
    max-width: 100%;
  }
  h1 {
    font-size: 2rem; /* 48px */
    line-height: 3rem;
  }
  h2 {
    font-size: 1.8rem; /* 36px */
    line-height: 2rem; /* 40px */
  }
  h3 {
    font-size: 1.2rem; /* 24px */
    line-height: 1.5rem;
  }
  h4 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.25rem; /* 28px */
  }
  .ant-modal-body {
    padding: 0.5rem !important;
  }
}
